/* eslint-disable max-len */
import Button from 'components/Basic/Button';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { downloadDataAsJSONFile } from '../util';
import { sendDebugData } from './api';
import { useLocation } from 'react-router-dom';

export default function GetDebugDataBtn(){
	const { uuid, externalId, mode, isSunroofAvailable, latLng, imagerySource, jpgUrl, dsmUrl, allRoofSegs, 
		createdAt, updatedAt, panel:selectedPanelDetails, imageryQuality } = 
		useSelector((state: RootState) => state.roofData.data);
	const { energyDerateRate, derateRateBeforeUserSwitchToNonDefaultModes } = useSelector((state: RootState) => state.QueryParams.data);

	const location = useLocation();
	const roofsegs = allRoofSegs.map((roofSeg) => {
		const { hullCoords, panels, ...rest} = roofSeg;
		const panelWithoutExtCoords = panels.map((panel)=>{
			const { exteriorCoords, ...rest } = panel;
			return {...rest};
		});
		return {...rest, panels:panelWithoutExtCoords};
	});
	let designUrl = '';

	const onClickHandler = () => {
		try{
			designUrl =`${window.location.origin}${location.pathname}${location.search}`;
			designUrl = designUrl.replace('show-data', '');
		}
		catch(ex){
			console.log('err:', ex);
		}
		const [lat, lng] = latLng.split(',');
		const debugData = {
			tool: 'AI tool',
			proposalId: externalId,
			uuid,
			mode,
			isSunroofAvailable,
			imageryQuality,
			lat,
			lng,
			imagerySource,
			selectedPanelDetails,
			jpgUrl,
			dsmUrl,
			allRoofSegs: roofsegs,
			designUrl,
			energyDerateRate,
			derateRateBeforeUserSwitchToNonDefaultModes,
			debugDataCapturedAt: new Date().toISOString(),
			proposalCreatedAt: createdAt,
			proposalUpdatedAt: updatedAt,
		};
		downloadDataAsJSONFile(debugData, `debug_data_${externalId}`);
		sendDebugData(JSON.stringify(debugData));
	};
	return (
		<Button 
			id="debug-btn"
			className='debug-btn btnHover'
			tooltipContent={'Upload & download debug info'}
			onClick ={onClickHandler}>
			<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5.80078 1L7.30478 2.504M10.6968 2.504L12.2008 1M6.60078 5.104V4.304C6.58628 3.9798 6.63761 3.65602 6.75168 3.3522C6.86575 3.04838 7.0402 2.77083 7.26449 2.53628C7.48877 2.30173 7.75826 2.11506 8.05668 1.98753C8.35509 1.85999 8.67625 1.79424 9.00078 1.79424C9.32531 1.79424 9.64647 1.85999 9.94489 1.98753C10.2433 2.11506 10.5128 2.30173 10.7371 2.53628C10.9614 2.77083 11.1358 3.04838 11.2499 3.3522C11.364 3.65602 11.4153 3.9798 11.4008 4.304V5.104" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M8.99922 15.4C6.35922 15.4 4.19922 13.24 4.19922 10.6V8.2C4.19922 7.35131 4.53636 6.53737 5.13648 5.93726C5.73659 5.33714 6.55053 5 7.39922 5H10.5992C11.4479 5 12.2618 5.33714 12.862 5.93726C13.4621 6.53737 13.7992 7.35131 13.7992 8.2V10.6C13.7992 13.24 11.6392 15.4 8.99922 15.4ZM8.99922 15.4V8.2" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M4.624 6.5999C3.08 6.4399 1.8 5.0799 1.8 3.3999M4.2 9.7999H1M1.8 16.1999C1.8 14.5199 3.16 13.0799 4.84 12.9999M16.176 3.3999C16.176 5.0799 14.896 6.4399 13.376 6.5999M17 9.7999H13.8M13.16 12.9999C14.84 13.0799 16.2 14.5199 16.2 16.1999" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
		</Button>
	);
}