import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setEnableFacetEdit } from 'store/slices/RoofDataSlice/roofDataSlice';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';

export default function EditFacet() {
	const dispatch = useDispatch<AppDispatch>();
	const { enableEditFacet } = useSelector((state: RootState) => state.roofData.data);
	const { activePanelMode, enableAddPanel } = useSelector((state: RootState) => state.panelSlice.data);
	const { hadTransition, currentActiveFacetEditMode, deletedRoofIndexes } = useSelector((state: RootState) => state.toolNewPostions.data);
	const queriesRunningInBackground = useQueriesRunningInBackGround();
	const shouldDisable = queriesRunningInBackground || !!currentActiveFacetEditMode ||
	activePanelMode && !enableAddPanel || hadTransition || deletedRoofIndexes.length > 0;
	const handleEnableFacet = () => {
		dispatch(setEnableFacetEdit({isEnable: !enableEditFacet}));
	};
	return (
		<>
			<Button 
				style={{
					background: `${enableEditFacet ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
					color: `${enableEditFacet ? '#003CFF' : '#28373E'}`,
				}}
				id="edit-facet"
				onClick={handleEnableFacet}
				disabled={shouldDisable}
				tooltipContent={enableEditFacet ? '' : 'Edit Facets'}
				className="btn-tools btn-editfacet">
				<svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 24 23" fill="none">
					<path d="M4.66667 20.0086L19.2519 18.0931M2.62963 18.4739V4.60447M21.1259 16.2015V6.95522M21.1259 
					16.2015C20.0909 16.2015 19.2519 17.0084 19.2519 18.0037C19.2519 18.9991 20.0909 19.806 21.1259 
					19.806C22.1609 19.806 23 18.9991 23 18.0037C23 17.0084 22.1609 16.2015 21.1259 16.2015ZM18.8139 
					4.76119L4.66667 2.84331M22.5111 5.23134C22.5111 6.22669 21.6721 7.03358 20.637 7.03358C19.602 
					7.03358 18.763 6.22669 18.763 5.23134C18.763 4.23599 19.602 3.4291 20.637 3.4291C21.6721 3.4291 
					22.5111 4.23599 22.5111 5.23134ZM4.74815 2.80224C4.74815 3.79759 3.9091 4.60448 2.87407 
					4.60448C1.83905 4.60448 1 3.79759 1 2.80224C1 1.80689 1.83905 1 2.87407 1C3.9091 1 4.74815 
					1.80689 4.74815 2.80224ZM4.74815 20.1978C4.74815 21.1931 3.9091 22 2.87407 22C1.83905 22 1 
					21.1931 1 20.1978C1 19.2024 1.83905 18.3955 2.87407 18.3955C3.9091 18.3955 4.74815 19.2024 
					4.74815 20.1978Z" stroke={`${enableEditFacet ? '#003CFF' : '#64686A'}`} strokeWidth="1.6"/>
				</svg>
			</Button>
		</>
	);

}