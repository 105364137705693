import { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Group } from 'react-konva';

import SolarPanel from '../../Panel/SolarPanel';
import UserDefinedTemporaryPanels from 'components/tool/Panel/UserDefinedTemporaryPanels';

import { getExistingPanelsGroupID, KonvaGroupNames } from '../../utils';
import type { Group as PanelGroupType } from 'konva/lib/Group';

type Props = {
	roofSegment: RasterRoofSegment;
}

function PanelsGroup({ roofSegment }: Props): JSX.Element {
	const { deletedPanels, activePanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const { invalidAzimuthFacets } = useSelector((state: RootState) => state.toolNewPostions.data);
	const deletedPanelIds = new Set(deletedPanels.map(p => p.panelId));
	const groupID = getExistingPanelsGroupID(roofSegment.id);
	const panelGroupRef = useRef<PanelGroupType>(null);

	useEffect(() => {
		panelGroupRef.current?.moveToTop();
	},[activePanelMode]);

	return (
		<Group
			id={groupID}
			listening={true}
			name={KonvaGroupNames.existingPanelsGroup}
			ref={panelGroupRef}
		>
			{
				roofSegment.panels.map((p) => {
					if (deletedPanelIds.has(p.id)) return null;
					return (
						<SolarPanel
							key={p.id}
							panelData={{ ...p, userMappedFacet: !!(p.userMapped || roofSegment.userMapped) }}
							panelId={p.id}
							invalidAzimuth={invalidAzimuthFacets[roofSegment.id]}
						/>
					);
				})
			}
			{/* unsaved panels added by user */}
			<UserDefinedTemporaryPanels roofSegmentId={roofSegment.id} />
		</Group>
	);
}
export default memo(PanelsGroup);