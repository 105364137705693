import { useSelector } from 'react-redux';
import { RootState } from 'store';
import DoneBtn from './ButtonDiv/CreateBtn';
import {
	EditToolOptionsDiv, MainDiv, ToolBarWrapper, PanelToolWrap, FacetPanelWrap,
	CalculatebtnWrap, ToolbarLabel, BtnWrap
} from './style';
import DrawBtn from './ButtonDiv/Facet/DrawBtn';
import EditToolOptions from './ButtonDiv/Facet/EditToolOptions';
import AddPanelButton from 'components/tool/Editor/ButtonDiv/Panel/AddPanelButton';
import MovePanelButton from 'components/tool/Editor/ButtonDiv/Panel/MovePanelButton';
import DeletePanelBtn from './ButtonDiv/Panel/DeletePanelBtn';
import CancelBtn from './ButtonDiv/Panel/CancelBtn';
import Button from 'components/Basic/Button';
import EditFacet from './ButtonDiv/Facet/EditFacet';

export default function EditorToolBar() {
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { editModeEnabled, deletedRoofIndexes, drawModeEnabled, roofIndexes, deleteObstructionsMode, newlyCreatedFacets, hadTransition } =
		useSelector((state: RootState) => state.toolNewPostions.data);
	const { activePanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const { enableEditFacet} = useSelector((state: RootState) => state.roofData.data);
	const hasUserMadeModificaiton = !!deletedRoofIndexes?.length || !!Object.keys(newlyCreatedFacets)?.length || !!roofIndexes?.length;
	const showDoneBtnIfUserHasModified = editModeEnabled && (hasUserMadeModificaiton || activePanelMode || drawModeEnabled 
		|| deleteObstructionsMode || enableEditFacet);
	const showCanelBtnInIndividualPanelFnMOde= editModeEnabled && (drawModeEnabled || deleteObstructionsMode || activePanelMode 
		|| hadTransition || enableEditFacet);

	if(showIrradiance){
		return null;
	}

	return (
		<MainDiv>
			<ToolBarWrapper className='toolbar-wrap'>
				<FacetPanelWrap className='toolbar-btn-wrap a'>
					<ToolbarLabel>Facets</ToolbarLabel>
					<EditToolOptionsDiv>
						<DrawBtn />
						<EditFacet />
						<EditToolOptions />
					</EditToolOptionsDiv>
				</FacetPanelWrap>
				<PanelToolWrap className='toolbar-btn-wrap'>
					<ToolbarLabel>Panels</ToolbarLabel>
					<EditToolOptionsDiv>
						{/* <PanelwithOreintation> */}
						<AddPanelButton />
						{/* </PanelwithOreintation> */}
						<MovePanelButton />
						<DeletePanelBtn />
					</EditToolOptionsDiv>

				</PanelToolWrap>
				<CalculatebtnWrap>
					{showDoneBtnIfUserHasModified && <BtnWrap><DoneBtn /></BtnWrap>}
					{showCanelBtnInIndividualPanelFnMOde && <CancelBtn />}
				</CalculatebtnWrap>

			</ToolBarWrapper>

		</MainDiv>
	);
}